import React, {useEffect, useState} from "react";
import TermsAndCondition from "./termsAndConditions";
import CookiePolicy from "./cookiePolicy";
import Layout from "../layout";
import { useParams } from "react-router-dom";

type PolicyProps = {};

const Policy = ()  => {
    const location = useParams();
    const [activeTabs, setActiveTab] = useState(location?.id || "terms");

    useEffect(() => {
        setActiveTab(location?.id || "terms");
    }, [location]);

    return (
        <Layout>
            <main className="w-full h-full">
                <div className="bg-blank px-4 md:px-8 lg:px-16 xl:px-32 py-8  w-full flex items-center justify-center">
                    <div className="w-full bg-white">
                        {/* header */}
                        <div className="flex justify-center w-full">
                            <div className="flex justify-around w-10/12 py-6 px-4">
                                <div className="">
                                    <h2
                                        className={`font-semibold px-6 cursor-pointer ${
                                            activeTabs === "terms" ? "text-[#3a8284]" : "text-[#c9c9d0]"
                                        }`}
                                        onClick={() => setActiveTab("terms")}
                                    >
                                        Terms & Conditions
                                    </h2>
                                </div>
                                <div className="">
                                    <h2
                                        className={`font-semibold px-6 cursor-pointer ${
                                            activeTabs === "cookie" ? "text-[#3a8284]" : "text-[#c9c9d0]"
                                        }`}
                                        onClick={() => setActiveTab("cookie")}
                                    >
                                        Cookie Policy
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {/* body */}
                        <div className="py-6 px-6">
                            {/* title */}
                            <div className="flex items-center">
                                {/* <div className="w-[5px] h-[22px] bg-primary"></div> */}
                                <h2 className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	border-hasselt pl-2">
                                    {activeTabs === "terms" ? "Terms & Conditions" : "Cookie policy"}
                                </h2>
                            </div>
                            {/* content */}
                            {activeTabs === "terms" ? <TermsAndCondition /> : <CookiePolicy />}
                        </div>
                    </div>
                </div>

            </main>
        </Layout>
    );
};
export default Policy;
