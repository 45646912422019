import React from "react";

// type TermsAndConditionsProps = {};

const TermsAndConditions = () => {
    return (
        <div className="pl-6">
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                Bijzondere Voorwaarden voor het gebruik van de internet website van Agifly
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 pb-3 font-semibold">
                Deze Bijzondere Voorwaarden kunnen op elk ogenblik door ons worden gewijzigd zonder
                voorafgaande kennisgeving. Door u toegang te verschaffen tot de internet website en deze te
                gebruiken, stemt u in met deze Bijzondere Voorwaarden.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
                I. Voorwerp
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 ">
                De internet website heeft tot doel om potentiële klanten een gebruiksvriendelijk platform ter
                beschikking te stellen die hen in staat stelt om een account aan te maken waarmee zij, de door Agifly
                ter beschikking gestelde informatie met betrekking tot de energieprestaties van hun woning kunnen
                beheren (d.w.z. corrigeren en aanvullen), indicatieve informatie (zonder verplichting tot
                nauwkeurigheid) kunnen krijgen over aanbevolen renovatiewerken, en geheel vrijblijvend, een audit
                kunnen aanvragen met het oog op het bekomen van een offline vrijblijvende offerte voor
                renovatiewerken die de energieprestaties van hun woning kunnen verbeteren, en die renovatiewerken
                eventueel door ondernemers te laten uitvoeren.
                <br />
                Deze Bijzondere Voorwaarden gelden voor elke gebruiker van de website. Indien u een vraag, klacht
                of bezorgdheid hebt over de manier waarop wij uw Persoonsgegevens verwerken, neem dan contact
                op met het Agifly ISM Office (<span className="text-primary">alain@agifly.be</span>).
                <br />
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                II. Gebruik van de internet website
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                1. Gebruikersidentificatie en wachtwoord
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Potentiële klanten van Agifly krijgen toegang tot de internet website. Zodra u uw account hebt
                aangemaakt, kan u de door Agifly ter beschikking gestelde data (verzameld uit officiële bronnen en
                aangevuld via artificiële intelligentie technieken), zelf beheren in een private omgeving (d.w.z. zonder
                dat andere gebruikers van het platform toegang hebben tot de door u beheerde informatie). U zult er
                zo goed mogelijk voor zorgen dat de informatie die u ons heeft bezorgd volledig en juist is.
                U bent verantwoordelijk voor de geheimhouding van het wachtwoord dat bij uw account hoort. U
                alleen bent verantwoordelijk voor alle activiteiten die zich afspelen onder uw account en u stemt
                ermee in om ons onmiddellijk op de hoogte te brengen als u merkt of vermoedt dat er ongeoorloofd
                gebruik wordt gemaakt van uw wachtwoord of account. Wij mogen uw account of profiel zonder
                voorafgaande kennisgeving opschorten als wij merken of vermoeden dat er ongeoorloofd gebruik
                van wordt gemaakt.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 ">
                2. Toegankelijkheid van de internet website
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Toegang tot en gebruik van de internet website zijn kosteloos. U stemt ermee in dat u gebonden
                bent door deze Bijzondere Voorwaarden zolang u een account op onze internet website bezit. Wij
                behouden ons het recht voor om in onbruik geraakte accounts te wissen. De door u beheerde
                informatie (met uitzondering van persoonsgegevens) zal evenwel verder worden gebruikt ten
                behoeve van verder onderzoek in verband met energieprestaties van woningen en renovatiewerken
                die nodig zijn om de energieprestaties te verbeteren.
                <br />
                Wij zullen ook gemachtigd zijn om het gebruik van de internet website met onmiddellijke ingang op
                te schorten of te beëindigen, mits een voorafgaande schriftelijke kennisgeving, wanneer u een
                inbreuk zou begaan tegen om het even welke clausule van deze Bijzondere Voorwaarden.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                3. Beveiliging
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-2">
                Agifly neemt alle redelijke technische en organisatorische maatregelen om de beveiliging van de
                internet website en de door de gebruiker beheerde informatie te beveiligen en de vertrouwelijkheid
                ervan te bewaren.
                <br />
                Wij zullen niet aansprakelijk zijn voor frauduleus gebruik van de internet website. Agifly zal niet
                verantwoordelijk zijn voor om het even welke storing in computersystemen of ongeoorloofde
                toegang tot of hacken van informatie.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                4. Verplichtingen van de gebruiker
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                U stemt ermee in:
            </p>
            {/* list */}
            <div className=" pl-4">
                <div className="flex">
                    <div className="mt-[8px] w-[1%]">
                        <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                    </div>
                    <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                        om de internet website uitsluitend te gebruiken voor doeleinden die toegelaten zijn door
                        deze Bijzondere Voorwaarden en alleen voor zover toegelaten door elke geldende wet,
                        voorschrift, regeling of algemeen aanvaarde praktijk in het rechtsgebied dat van toepassing
                        is:
                    </p>
                </div>
                <div className="flex pt-2">
                    <div className="mt-[8px] w-[1%]">
                        <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                    </div>
                    <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                        om de internet website niet te gebruiken voor illegale doeleinden of voor doeleinden die de
                        rechten van Agifly of derden zouden kunnen aantasten en meer bepaald om geen valse,
                        onjuiste of misleidende informatie te verschaffen;
                    </p>
                </div>
                <div className="flex py-2">
                    <div className="mt-[8px] w-[1%]">
                        <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                    </div>
                    <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                        geen inbreuk te plegen op de intellectuele eigendomsrechten of privacy rechten van Agifly of
                        derden;
                    </p>
                </div>
                <div className="flex">
                    <div className="mt-[8px] w-[1%]">
                        <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                    </div>
                    <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                        om te voldoen aan de veiligheidsvoorschriften die gelden in verband met het gebruik van de
                        internet website.
                    </p>
                </div>
            </div>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                III. Aansprakelijkheid en waarborgen
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                U aanvaardt de internet website "AS IS" (d.w.z. zoals deze door Agifly wordt aangeboden), met alle
                fouten en tekortkomingen en zonder enige uitdrukkelijke of stilzwijgende waarborg, garantie of
                aanwijzing, van welke aard dan ook, en zonder enige waarborg op juistheid, volledigheid, kwaliteit,
                verhandelbaarheid, geschiktheid voor een bepaald doel of het niet-plegen van een inbreuk.
                Wij leveren alle commercieel redelijke inspanningen om ervoor te zorgen dat onze internet website
                7 dagen per week en 24 uur per dag beschikbaar zijn. Toch mogen wij de toegang onderbreken om
                elke door ons gekozen reden, onder andere wegens onderhoud of een upgrade of voor alle andere en
                meer bepaald technische redenen. Wij garanderen geen ononderbroken toegang en zijn op geen
                enkele manier verantwoordelijk voor onderbrekingen van de toegang tot de internet website en de
                gevolgen die hieruit zouden voortvloeien voor u of derden.

            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Wij garanderen niet dat het gebruik van de internet website foutenvrij zal zijn. Wij zullen redelijke
                inspanningen leveren om elke fout snel te corrigeren. Een dergelijke correctie is uw enig en
                uitsluitend verweermiddel.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 ">
                Wij zullen niet aansprakelijk zijn voor onrechtstreekse of gevolgschade, zoals (maar niet beperkt tot)
                verlies of aantasting van data. Wij zullen evenmin aansprakelijk zijn voor schade aan of voor tijdelijke
                of permanente onbeschikbaarheid van uw IT-uitrusting wanneer u zich toegang verschaft tot de
                internet website, bij het navigeren door de pagina’s ervan of algemeen tijdens het verzenden van
                bestanden en computerprogramma’s van de website naar de uitrusting van de ontvanger. Meer
                specifiek zijn wij niet verantwoordelijk voor virussen die zouden worden doorgegeven via onze
                internet website.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Met uitzondering van fraude of opzettelijke bedoeling om te schaden zal onze aansprakelijkheid in
                totaal beperkt zijn tot 50 EUR.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 ">
                U stemt ermee in om ons op de hoogte te brengen van elke klacht in verband met uw gebruik van de
                internet website binnen een periode van 48 uren nadat u de oorzaak van de klacht opgemerkt hebt.
                Na het verstrijken van de vermelde periode van 6 maanden worden uw klachten onontvankelijk.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                IV. Verwerking van persoonsgegevens
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Op de verwerking van persoonsgegevens is de Belgische wet van 8 december 1992 tot bescherming
                van de persoonlijke levensfeer ten opzichte van de verwerking van persoonsgegevens van
                toepassing (zoals gewijzigd door de wet van 11 december 1998 tot omzetting van Richtlijn 95/46/EG
                van het Europees Parlement en de Raad van 24 oktober 1995 betreffende de bescherming van
                natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije
                verkeer van die gegevens).
                <br />
                Door de internet website te gebruiken, erkent u dat u op de hoogte werd gebracht van en dat u hebt
                ingestemd met het gebruik, de verwerking en de overdracht van de informatie die u meedeelt door
                middel van de internet website. Als u informatie bezorgt over derden zult u ze informeren over de
                verwerking van hun persoonsgegevens door ons en zult u hun voorafgaande toestemming hiervoor
                verkrijgen.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                1. Wat wij vergaren:
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Wij kunnen de volgende informatie vergaren:
            </p>
            <div className="flex pt-2 pl-4">
                <div className="mt-[8px] w-[1%]">
                    <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                </div>
                <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    voornaam en familienaam
                </p>
            </div>
            <div className="flex pt-2 pl-4">
                <div className="mt-[8px] w-[1%]">
                    <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                </div>
                <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    contactinformatie, inclusief e-mailadres
                </p>
            </div>
            <div className="flex pt-2 pl-4">
                <div className="mt-[8px] w-[1%]">
                    <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                </div>
                <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    demografische informatie
                </p>
            </div>
            <div className="flex pt-2 pl-4">
                <div className="mt-[8px] w-[1%]">
                    <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                </div>
                <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    elke informatie die u ons vrijwillig hebt bezorgd via het platform of
                    tijdens de audit
                </p>
            </div>
            <div className="flex pt-2 pl-4">
                <div className="mt-[8px] w-[1%]">
                    <div className="w-[6px] bg-black h-[6px] rounded-full"></div>
                </div>
                <p className="pl-2 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    andere informatie die relevant is voor de vrijblijvende uitvoering van een audit in uw woning en
                    voor het offline afleveren van een aanbod voor renovatiewerken die de energieprestaties van uw
                    woning verbeteren.
                </p>
            </div>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                2. Wat wij doen met de vergaarde informatie
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Wij verzamelen en verwerken de persoonsgegevens die wij van u ontvangen teneinde u toegang te
                verlenen tot en gebruik te laten maken van ons internet website en voor de uitvoering van de
                aannemersovereenkomst, het klantenbeheer, de boekhouding, directe marketingactiviteiten en
                onderzoek naar energiebesparende renovatiewerken. Daarnaast kunnen de persoonsgegevens die
                wij van u ontvangen ook worden gebruikt om, op uw verzoek, een energieprestatie-audit van uw
                woning en de werkzaamheden uit te voeren die nodig kunnen zijn om deze prestaties te verbeteren.
                Wij gaan de verbintenis aan om ervoor te zorgen dat uw informatie afdoende beschermd is. Om
                ongeoorloofde toegang of openbaarmaking te voorkomen, hebben wij geschikte fysieke,
                elektronische en organisatorische procedures ingevoerd om de persoonsgegevens die wij online
                vergaren afdoende te beschermen en te beveiligen.
                <br />
                Persoonsgegevens worden uitsluitend voor interne doeleinden gebruikt en niet voor commerciële
                doeleinden aan andere organisaties doorgegeven, hoewel wij ons het recht voorbehouden om ze
                door te geven aan elke onderneming of entiteit van de groep waartoe Agifly behoort, aan
                onderaannemers die de renovatiewerken zullen uitvoeren, aan partners van Agifly die samenwerken
                met Agifly om dit te realiseren.
                <br />
                Persoonsgegevens zullen enkel worden doorgegeven aan verwerkers, ontvangers en/of derden voor
                zover dit noodzakelijk is in het kader van voormelde doeleinden voor de verwerking en dit gedurende
                de looptijd van de contractuele of wettelijke verplichtingen (m.i.v. de garantieverplichtingen).
                De rechtsgronden voor de verwerking van de persoonsgegevens die wij van u ontvangen zijn de
                uitvoering van eventuele overeenkomsten, het vervullen van wettelijke en reglementaire
                verplichtingen en/of het gerechtvaardigd belang.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                3. Gebruikersrechten
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                U kunt te allen tijde uw Persoonsgegevens inzien, corrigeren, bijwerken of verzoeken om verwijdering
                ervan. U kunt ook bezwaar maken tegen de verwerking van uw Persoonsgegevens, ons verzoeken de
                verwerking van uw Persoonsgegevens te beperken of om de overdraagbaarheid van uw
                Persoonsgegevens verzoeken. U hebt ook het recht om te allen tijde af te zien van alle
                marketingcommunicatie die wij u toesturen. Indien u uw rechten wenst uit te oefenen of indien u een
                vraag, klacht of bezorgdheid hebt over de manier waarop wij uw Persoonsgegevens verwerken, neem
                dan contact op met het Agifly ISM Office (<span className="text-primary">alain@agifly.be.</span>).
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Door in te loggen op het platform, bevestigt u dat u afdoende werd geïnformeerd over de verwerking
                van uw persoonsgegevens en over uw rechten op inzage, verbetering, het wissen ervan en het maken
                van enig bezwaar.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                V. Gebruik van cookies
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Cookies zijn kleine tekstbestanden die op uw computer of mobiel apparaat worden geplaatst
                wanneer u een website bezoekt. Cookies worden veelvuldig gebruikt door eigenaars van websites
                om hun websites te laten werken, of om deze efficiënter te laten werken, alsook om informatie te
                rapporteren.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3">
                Cookies hebben veel verschillende functies, zoals u toelaten om efficiënt te navigeren tussen
                pagina's, het onthouden van uw voorkeuren, en in het algemeen, om de gebruikerservaring te
                verbeteren. Cookies kunnen eveneens helpen om ervoor te zorgen dat de advertenties die u online
                ziet relevanter zijn voor u en uw interesses.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Meer algemeen helpen cookies Agifly om u een betere internet website te bieden. Een cookie geeft
                ons nooit toegang tot uw computer of tot informatie over u, met uitzondering van de gegevens die u
                met ons wilt delen.
                <br />
                U kunt cookies aanvaarden of afwijzen. De meeste webbrowsers aanvaarden cookies automatisch,
                maar u kan uw browserinstellingen gewoonlijk wijzigen om cookies af te wijzen als u dat liever hebt.
                Dit kan wel betekenen dat u niet alle voordelen geniet van de internet website.
                Voor meer informatie, zie het <span className="underline"> uitgebreide Cookie Policy</span> beleid van Agifly bv.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                VI. Vertrouwelijkheid
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Vertrouwelijke informatie mag alleen worden gebruikt voor de doeleinden waarvoor ze werd
                meegedeeld.Vertrouwelijke informatie is alle informatie die als vertrouwelijk werd aangemerkt of
                waarvan door haar aard en de manier waarop ze werd bekendgemaakt redelijkerwijze kan worden
                verondersteld dat ze vertrouwelijk is (zelfs als ze niet als zodanig werd aangemerkt). Vertrouwelijke
                informatie omvat alle wetenschappelijke en technische informatie, alsook de commerciële en
                financiële informatie, ongeacht de vorm waarin deze informatie wordt verstrekt.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                VII. Copyright
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                De inhoud van onze internet website is beschermd door intellectuele eigendomsrechten. U hebt
                alleen de toestemming om de inhoud van onze internet website te lezen te gebruiken waarvoor het
                is bedoeld.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                VIII. Links naar andere websites
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Onze internet website kunnen links bevatten die u in staat stellen om gemakkelijk andere
                interessante websites te bezoeken. Maar zodra u deze links hebt gebruikt om onze website te
                verlaten, dient u zich ervan bewust te zijn dat wij geen enkele controle over dergelijke websites
                hebben. Daarom kunnen wij niet verantwoordelijk zijn voor de bescherming en privacy van deze
                informatie die u ons bezorgt wanneer u dergelijke websites bezoekt, terwijl de onderhavige
                Bijzondere Voorwaarden niet van toepassing zijn op deze websites. U dient voorzichtig te werk te
                gaan en de juridische disclaimer en de privacyverklaring die voor de website in kwestie gelden te
                lezen.
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
                IX. Algemene bepalingen
            </p>
            <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                Indien wordt vastgesteld dat een bepaling van deze Bijzondere Voorwaarden volledig of gedeeltelijk
                onafdwingbaar of ongeldig is, dan zal een dergelijke bepaling (of een gedeelte ervan) toch maximaal
                bindend blijven, in de mate waarin dit juridisch toegelaten is.
                Indien wij er niet in slagen om een recht of bepaling van deze Bijzondere Voorwaarden uit te oefenen
                of af te dwingen, dan betekent dit niet dat wij zouden afzien van een dergelijk recht of een dergelijke
                bepaling.
                <br />
                Op deze Bijzondere Voorwaarden zijn de Belgische wetten van toepassing. Voor elk geschil dat zou
                voortvloeien uit of in verband zou staan met deze Bijzondere Voorwaarden zijn uitsluitend de
                rechtbanken van Kortrijk (België) bevoegd.
            </p>
        </div>
    );
};
export default TermsAndConditions;
