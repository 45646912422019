import React, { FC, useEffect, useContext } from "react";
import { GlobalContext } from "../context/globalContext/globalContext";
import { Routes, Route,useNavigate } from "react-router-dom";
import { Sessions } from "../utils/session";
// pages
import Homepage from "../containers/homepage";
import PrivacyPolicy from "../components/privacyPolicy";
import { useCookies } from 'react-cookie'

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
  const navigate = useNavigate();

  const [cookies, setCookies] = useCookies();

  // global state
  const { dispatch , state}: any = useContext(GlobalContext);
  //useEffect to create new visited user Id which requires at the end for form submission
  useEffect(() => {
    Sessions(cookies,setCookies,dispatch);
  }, [dispatch]);

  useEffect(() => {
    console.log("GlobalContext state update",state)

    if(state.redirection){
      console.log("Go to form",state.session.id)
      // navigate("/v/vraag/1/typewoning?id="+state.session.id)
      window.location.replace("/v/vraag/1/typewoning?id="+state.session.id);

    }
  }, [state]);

  return (
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy-policy/:id" element={<PrivacyPolicy/>}/>

      </Routes>
  );
};
export default PagesRoutes;
