import React, { FC, useRef, useEffect } from "react";
// layout
import Layout from "../../components/layout";
import SectionForm from "../../components/homepage/sectionForm";
import SectionSteps from "../../components/homepage/sectionSteps";
import SectionPros from "../../components/homepage/sectionPros";
import SectionH2h from "../../components/homepage/sectionHome2home";

interface HomePageInterface {}

const HeaderFilter: FC<HomePageInterface> = () => {
  // const refH2h = useRef();


  
  
  useEffect(() => {

  }, []);

  return (
    <Layout>
      <>
        <SectionForm />
        <SectionSteps />
        {/* <SectionPros /> */}
        <SectionH2h />
      </>
    </Layout>
  );
};

export default HeaderFilter;
