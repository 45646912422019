import React, {FC, useContext, useState} from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";
import Lottie from "lottie-react";
import AnimationHouse1 from "../../assets/lottie/AnimationHouse1.json";
import AnimationHouse2 from "../../assets/lottie/AnimationHouse2.json";
import AnimationHouse3 from "../../assets/lottie/AnimationHouse3.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'
import architect from './../../assets/picture/architect.jpg'
import house from './../../assets/picture/eco-house.jpg'

import { SessionsUpdate } from "../../utils/session";
// pages
import { useCookies } from 'react-cookie'
import EnergiescanAanvragen from "../modals/energiescanAanvragen";
import CallMeBack from "../modals/callMeBack";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */


interface SectionFormInterface {}

const SectionForm: FC<SectionFormInterface> = () => {
  const location = useLocation();
  // global state
  const { state,dispatch }: any = useContext(GlobalContext);
  const [cookies, setCookies] = useCookies();
  const [openEnergiescanAanvragen, setOpenEnergiescanAanvragen] = useState(false);
  const [openCallMeBack, setOpenCallMeBack] = useState(false);

  const handleSubmit = (event:any)  => {
    event.preventDefault();
    const adress = event?.target[0]?.value;
    if(adress){
      SessionsUpdate(state.session.id,{address:event?.target[0]?.value}, cookies, setCookies, dispatch)
    }else{
      SessionsUpdate(state?.session?.id,{address:null}, cookies, setCookies, dispatch)
    }

  }

  return (
      <section className="container  mx-auto  p-8 ">
        <div className="md:flex">
          <div className="hidden md:flex md:w-1/8 items-center self-center	justify-items-center text-center	">
            <Lottie animationData={AnimationHouse1} loop={true} autoplay={true} className="max-w-[20rem]" />
          </div>
          <div className="flex-auto">
            <div className="flex items-center justify-center w-full text-center">
              <div className="w-full max-w-3xl bg-white rounded-lg shadow-xl">
                <div
                    style={{
                      height: 175,
                      backgroundSize: 'cover',
                      borderTopLeftRadius: '0.5em',
                      borderTopRightRadius: '0.5em',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundImage: `url(${architect})`,
                    }}
                />
                <div className="p-8 pt-4 pb-4" style={{ minHeight: 435 }}>
                  <h1 className="text-xl font-bold">De renovatiebegeleider bij je thuis</h1>
                  <p className="mt-3 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    Wil je graag zorgen voor meer comfort in je woning met een lager energieverbruik? Zit je met vragen over hoe je je woning toekomstbestendig kan maken? Weet je niet goed waar te starten en kan je wel wat hulp gebruiken?
                  </p>
                  <p className="mt-8 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                    Laat dan hier je gegevens achter:
                  </p>

                  <button
                      type="submit"
                      className="mt-12 w-full lg:w-auto text-white bg-hasselt hover:bg-hasselt-dark focus:ring-4 focus:outline-none focus:ring-hasselt-accent font-medium text-sm px-5 py-2.5 text-center content-end h-12 self-center "
                      onClick={() => {setOpenEnergiescanAanvragen(true)}}
                  >
                    Energiescan aanvragen
                  </button>

                  <p className="mt-16 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                    Ik wil meer informatie (De renovatiebegeleider neemt <br/>contact met je op.) <span className="text-hasselt no-underline hover:underline cursor-pointer" onClick={() => {setOpenCallMeBack(true)}}>Bel mij terug</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-auto right-box">
            <div className="flex items-center justify-center w-full text-center">
              <div className="w-full max-w-3xl bg-white rounded-lg shadow-xl">
                <div
                    style={{
                      height: 175,
                      backgroundSize: 'cover',
                      borderTopLeftRadius: '0.5em',
                      borderTopRightRadius: '0.5em',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundImage: `url(${house})`,
                    }}
                />
                <div className="p-8 pt-4 pb-4" style={{ minHeight: 435 }}>
                  <h1 className="text-xl font-bold">Is je woning klaar voor morgen?</h1>
                  <p className="mt-3 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                    Wil je besparen op energie of op water? Of heb je het te warm in de zomer? Is je dak aan vervanging toe? Jij bepaalt wat je belangrijk vindt. Wij zeggen wat het opbrengt en hoeveel het zal kosten. Handig toch?
                  </p>
                  <p className="mt-8 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                    Je kan zelf een handig rapportje generen door enkele gegevens in te vullen in deze handige online tool ‘Check je huis’.
                  </p>

                  <button
                      type="submit"
                      className="mt-4 w-full lg:w-auto text-white bg-hasselt hover:bg-hasselt-dark focus:ring-4 focus:outline-none focus:ring-hasselt-accent font-medium text-sm px-5 py-2.5 text-center content-end h-12 self-center "
                      onClick={handleSubmit}
                  >
                    Check mijn huis
                  </button>

                  <p className="mt-6 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                    We doen ons best om ‘Check je huis’ up to date te houden. De energieprijzen in de tool worden maandelijks geactualiseerd. Hou er rekening mee dat de gesimuleerde besparing berekend wordt met de prijzen op het moment van simulatie.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block flex-auto md:w-1/8 items-center self-center	justify-items-center ">
            <Lottie animationData={AnimationHouse2} loop={true} autoplay={true} className="max-w-[20rem]" />
            <Lottie animationData={AnimationHouse3} loop={true} autoplay={true} className="max-w-[15rem] float-right 	" />
          </div>
        </div>

        <CallMeBack open={openCallMeBack} setOpen={setOpenCallMeBack} />

        <EnergiescanAanvragen open={openEnergiescanAanvragen} setOpen={setOpenEnergiescanAanvragen} />
      </section>
  );
};

export default SectionForm;
