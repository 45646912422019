import React, { FC, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreen, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'


interface SectionH2hInterface {
 
}

const SectionH2h: FC<SectionH2hInterface> = () => {

  return (
    <section className="bg-hasselt px-16"  id="h2h">
      <div className="container mx-auto py-16 sm:flex text-white">
        <div className="w-full sm:w-4/12">
          <img src={require("../../assets/picture/hp/house.png")} alt="House's logo" />
        </div>
        <div className="text-center w-full sm:w-8/12 sm:text-left sm:flex align-middle	place-items-center	items-center">
          <div>
            <h1 className="text-xl md:text-2xl xl:text-2xl 2xl:text-4xl font-bold w-full">Home 2 Home</h1>
            <p className="mt-4 xs:w-6/12	">Verbouwplannen? Pak het verstandig aan. Renoveren waar nodig</p>
            <a href="tel:037716625">
              <button type="button"className="mt-4 text-black bg-white hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
                <FontAwesomeIcon icon={faMobileScreen} className="w-5 h-5 mr-2 -ml-1" />  
                03 771 66 25
              </button>
            </a>
            <a href="mailto:info@h2hvastgoed.be">
              <button type="button"className="mt-4  text-black bg-white hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
                <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5 mr-2 -ml-1" />
                info@h2hvastgoed.be
              </button>
            </a>
            <a href="https://www.h2hreno.be" target="_blank">
              <button type="button"className="mt-4  text-black bg-white hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
                  <FontAwesomeIcon icon={faLink} className="w-5 h-5 mr-2 -ml-1" />
                  www.h2hreno.be
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionH2h;
