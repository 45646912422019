import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";


interface SectionStepsInterface {}

const SectionSteps: FC<SectionStepsInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  const handleSubmit = (event:any)  => {  
  }

  return (
    <section className="bg-white">
    <div className="container mx-auto py-12  px-16">
      <h1 className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	border-hasselt pl-2">Dit zijn de verschillende stappen</h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 gap-4">
        <div className="mt-16 border-l-2	border-hasselt px-4 relative border-t-4 border-t-hasselt-accent pt-8 border-t-dashed	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            1
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Telefonisch contact</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">De renovatiebegeleider van Home2Home, in opdracht van Stad Hasselt, belt jou op om samen de verschillende stappen van het proces te doorlopen en een huisbezoek in te plannen.</p>
        </div>
        <div className="mt-16 border-l-2	border-hasselt px-4 relative border-t-4 border-t-hasselt-accent pt-8 border-t-dashed	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            2
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Huisbezoek</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">De renovatiebegeleider van Home2Home komt langs bij jouw thuis om alle technische aspecten van het huis te bekijken. Door een open gesprek aan te gaan weet de renovatiebegeleider welke werken jij zou willen uitvoeren.</p>
        </div>
        <div className="mt-16 border-l-2	border-hasselt px-4 relative lg:border-t-4 lg:border-t-hasselt-accent pt-8 border-t-dashed	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            3
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Renovatieadvies</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">Op basis van de rondgang in huis, maakt de renovatiebegeleider een advies op: welke werken zijn nodig, welke werken zijn prioriteit,…</p>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">Daarbij maken jullie samen een eerste inschatting van de kosten en overlopen jullie de mogelijke premies die van toepassing zijn.</p>
        </div>
        <div className="mt-16 border-l-2	border-hasselt px-4 relative border-t-4 border-t-hasselt-accent pt-8 border-t-dashed	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            4
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Offertes</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">De renovatiebegeleider vraagt samen met jou offertes op maat op voor de werken die jij graag wil uitvoeren aan je woning.</p>
        </div>
        <div className="mt-16 border-l-2	border-hasselt px-4 relative border-t-4 border-t-hasselt-accent pt-8 border-t-dashed	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            5
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Uitvoering werken</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">Op jouw vraag plant en controleert de renovatiebegeleider de werken die worden uitgevoerd.</p>
        </div>
        <div className="mt-16 border-l-2	border-hasselt px-4 relative pt-8 	">
          <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="hover:text-white inline-block font-bold text-2xl leading-tight uppercase rounded-full focus:outline-none bg-white hover:bg-hasselt border-4 border-hasselt h-16 w-16 absolute left-[-2rem] top-[-2rem]" >
            6
          </button>
          <h2 className="text-base md:text-base xl:text-lg  2xl:text-xl font-semibold">Aanvraag premies</h2>
          <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">Wanneer alle werken uitgevoerd zijn, vraagt de renovatiebegeleider de nodige premies aan.</p>
        </div>
      </div>
      <div className="flex flex-wrap justify-end mt-8">
        <p className="grow	text-right  md:text-xs xl:text-sm 2xl:text-base text-gray-900 w-full">Wilt u meer informatie over het proces, neem dan contact met ons op</p>
        <a href='#h2h'><button type="button" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-hasselt-accent font-semibold px-5 py-3 mt-4">Contacteer ons</button></a>
      </div>
    </div>
</section>
  );
};

export default SectionSteps;
