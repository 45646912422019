import React, { FC, useState, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { ScreenSize } from "../../utils/windowDimension";
import { useLocation, useNavigate } from "react-router-dom";

import logoHasselt from '../../assets/logo/LogoHasselt.svg';
import houseHeader from '../../assets/picture/house-header.png';
import CallMeBack from '../../components/modals/callMeBack';
import EnergiescanAanvragen from '../modals/energiescanAanvragen';
import '../../styles/header.css';

// languages
import Dutch from "../../languages/nl";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
    const [openCallMeBack, setOpenCallMeBack] = useState(false);
    const [openEnergiescanAanvragen, setOpenEnergiescanAanvragen] = useState(false);

    // global state
    const { state ,  dispatch }: any = useContext(GlobalContext);
    // to Get ScreenSize
    const screenSize = ScreenSize();
    // toggle state to show and hide language option
    const [toggle, setToggle] = useState(false);
    const [openNavMenu, setOpenNavMenu] = useState(false);
    // state to show default selected language
    const [selectedLanguage, setSelectedLanguage] = useState(state?.language?.language.toUpperCase());


    return (
        <>
            <header className="z-40">
                <nav className="bg-white py-2.5  w-full z-20 top-0 left-0 border-b border-gray-200 h-20 md:h-20 xl:h-28 2xl:h-36 shadow">
                    <a href="/" className="header-link">
                        <img src={houseHeader} className="header-image" alt="Hasselt Logo" />
                    </a>
                    <div className="container flex flex-wrap items-center mx-auto h-full px-2 md:p-0 xl:p-2 2xl:p-5 top-header-container">
                        <a href="/" className="items-center h-full log-hass">
                            <img src={logoHasselt} className="h-9 md:h-full" alt="Hasselt Logo" />
                        </a>
                        <div className="flex items-center" style={{ marginLeft: 'auto' }}>
                            <button onClick={() => setOpenNavMenu(!openNavMenu)} type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className={`${openNavMenu ? "hidden" : ""} w-6 h-6`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                <svg className={`${openNavMenu ? "" : "hidden"} w-6 h-6`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div>
                        <div className="hidden justify-between items-end content-end md:flex w-auto"  style={{ marginLeft: 'auto' }}>
                            <button type="button" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-hasselt-accent font-semibold px-5 py-3 mr-8" onClick={() => {setOpenCallMeBack(true)}}>Bel mij terug</button>
                            <button type="button" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-white bg-hasselt border-2 border-hasselt hover:bg-hasselt-dark hover:border-hasselt-dark focus:ring-4 focus:ring-hasselt-accent font-semibold	px-5 py-3" onClick={() => {setOpenEnergiescanAanvragen(true)}}>Energiescan aanvragen</button>
                        </div>
                    </div>
                    <div className={`bg-white mt-0  shadow w-[100vw] px-2 ${openNavMenu ? "" : "hidden "} md:hidden `} >
                        <div className="container py-4 flex flex-wrap items-center justify-between mx-auto h-full ">
                            <ul className="list-none	">
                                <li className="my-2">
                                    <button type="button" className="text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-hasselt-accent font-semibold px-5 py-3 mr-8" onClick={() => {setOpenCallMeBack(true)}}>Bel mij terug</button>
                                </li>
                                <li className="my-2">
                                    <button type="button" className="text-white bg-hasselt border-2 border-hasselt hover:bg-hasselt-dark hover:border-hasselt-dark focus:ring-4 focus:ring-hasselt-accent font-semibold	px-5 py-3" onClick={() => {setOpenEnergiescanAanvragen(true)}}>Energiescan aanvragen</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <CallMeBack open={openCallMeBack} setOpen={setOpenCallMeBack} />
            <EnergiescanAanvragen open={openEnergiescanAanvragen} setOpen={setOpenEnergiescanAanvragen} />

        </>
    );
};

export default Header;
