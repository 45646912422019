import React, { FC, useContext,useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation, Link } from "react-router-dom";
import logoGent from '../../assets/logo/logoGent.svg';
import logoAgifly from '../../assets/logo/Agifly.png';
import CallMeBack from '../../components/modals/callMeBack';
import EnergiescanAanvragen from '../modals/energiescanAanvragen';
interface FooterInterface {}

const Footer: FC<FooterInterface> = () => {
    const location = useLocation();
    const [openCallMeBack, setOpenCallMeBack] = useState(false);
    const [openEnergiescanAanvragen, setOpenEnergiescanAanvragen] = useState(false);

    // global state
    const { state }: any = useContext(GlobalContext);
    return (
        <>
            <footer className="mt-auto bg-white w-full drop-shadow-lg">
                <div className="w-full mx-auto p-4 shadow-2xl bg-white container mx-auto  my-4 grid justify-center sm:grid-cols-2 sm:gap-2 md:grid-cols-4 md:gap-4 md:justify-start lg:grid-cols-6 lg:gap-6 text-sm  text-center sm:text-left	">
                    <div>
                        <h6 className="my-2 font-semibold">Links</h6>
                        <ul>
                            <li><Link to="/privacy-policy/cookie" target="_blank">Cookiebeleid</Link></li>
                            <li><Link to="/privacy-policy/terms" target="_blank">Privacybeleid</Link></li>
                            {/*
          <li><a href="https://www.hasselt.be/nl/privacyverklaring-nieuwsbrief" target="_blank">Privacybeleid Nieuwsbrieven</a></li>
*/}
                        </ul>
                    </div>
                    <div>
                        <h6 className="my-2 font-semibold">Contact</h6>
                        <ul>
                            <li onClick={() => {setOpenCallMeBack(true)}} className="cursor-pointer	">Bel mij terug</li>
                            <li onClick={() => {setOpenEnergiescanAanvragen(true)}} className="cursor-pointer	">Energiescan aanvragen</li>
                        </ul>
                    </div>
                    <div className="col-span-2 items-center mx-auto text-center">
                        <h6 className="my-2 font-semibold">Volg ons</h6>
                        <div className="flex sm:justify-start justify-center items-center flex-wrap space-x-2">
                            <a href="https://www.facebook.com/StadHasselt/" target="_blank">
                                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out bg-black hover:bg-hasselt" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-5 h-5">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
                                    </svg>
                                </button>
                            </a>
                            <a href="https://www.instagram.com/stadhasselt/?hl=en" target="_blank">
                                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out bg-black hover:bg-hasselt" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-5 h-5">
                                        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </button>
                            </a>
                            <a href="https://www.linkedin.com/company/stad-hasselt/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=be" target="_blank">
                                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out bg-black hover:bg-hasselt" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-5 h-5">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="mx-auto text-center">
                        <h6 className="my-2 font-semibold">Mogelijk gemaakt dankzij</h6>
                        <a href="https://stad.gent/nl" target="_blank"><img src={logoGent} className="w-24 mx-auto justify-center" width="132"/></a>
                    </div>
                    <div className="mx-auto text-center">
                        <h6 className="my-2 font-semibold">Ontwikkeld door</h6>
                        <a href="https://agifly.be" target="_blank"><img src={logoAgifly} className="w-24  mx-auto justify-center" width="180"/></a>
                    </div>
                </div>
            </footer>
            <CallMeBack open={openCallMeBack} setOpen={setOpenCallMeBack} />
            <EnergiescanAanvragen open={openEnergiescanAanvragen} setOpen={setOpenEnergiescanAanvragen} />

        </>
    );
};

export default Footer;
