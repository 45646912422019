import React, { FC, useContext,Fragment,useState,useRef } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'

import { Dialog, Transition } from '@headlessui/react'
import { addBeno, addContact } from "../../context/actions/forms";


interface EnergiescanAanvragenInterface {
  open : boolean,
  setOpen : any
}

const EnergiescanAanvragen: FC<EnergiescanAanvragenInterface> = (props) => {
  const cancelButtonRef = useRef(null)

  const [ errName, setErrName ] = useState(false);
  const [ errAddress, setErrAddress ] = useState(false);
  const [ errPhone, setErrPhone ] = useState(false);
  const [ errEmail, setErrEmail ] = useState(false);
  const [ errType, setErrType ] = useState(false);
  const [ errComment, setErrComment ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false)

  // global state
  const { state }: any = useContext(GlobalContext);

  const handleSubmit = (event:any)  => {
    event.preventDefault();
    setError(false)
    setSuccess(false)

    let name = event.target[0].value,
      firstname = event.target[1].value,
      straat = event.target[2].value,
      huisnr = event.target[3].value,
      postcode = event.target[4].value,
      gemeente = event.target[5].value,
      email = event.target[6].value,
      phone = event.target[7].value,
      type = event.target[8].value,
      comment = event.target[9].value


    let err = 0;
    let address = `${straat} ${huisnr} ${postcode} ${gemeente}`

    if(name.length <= 2){
      setErrName(true)
      err++
    }else{
      setErrName(false)
    }

    if(address.length <= 5){
      setErrAddress(true)
      err++
    }else{
      setErrAddress(false)
    }


    if(email){
      let emailCheck=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emailCheck.test(email) ? setErrEmail(false) : setErrEmail(true)
      emailCheck.test(email) ? setErrEmail(false) : err++
    }else{
      setErrEmail(true);
      err++
    }

    if(phone.length > 0 ){
      let phoneCheck=/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
      phoneCheck.test(phone) ? setErrPhone(false) : setErrPhone(true);
      phoneCheck.test(phone) ? setErrPhone(false) : err++;

    }else{
      setErrPhone(false)
    }

    let typeSplitted = type.split("-")
    if( Number(typeSplitted[0]) > 0 && Number(typeSplitted[0]) < 5 && typeSplitted[1].length > 2 ){
      setErrType(false)
    }else{
      setErrType(true)
      err++
    }

    if(err === 0){
      const coach_id = "1"
      // To fix
      addBeno({straat, huisnr, postcode, gemeente, coach_id,comment, firstname, name, email,type,phone}).then((response) => {
        console.log("response",response)
      })
      .catch((error) => {
        console.log(error)
      });

      addContact({name ,address , email, phone, type:Number(typeSplitted[0]), typeText:typeSplitted[1] ,comment,from:2}).then((response) => {
        setSuccess(true)
        console.log("response",response)
      })
          .catch((error) => {
            console.log(error)
            setError(true)
            setSuccess(false)
          });
    }
  }

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <form className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full  sm:w-full md:max-w-2xl" onSubmit={handleSubmit}>
                  <Dialog.Panel className="">
                    {error ?
                        <div className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg clear-both	" role="alert">
                          <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Info</span>
                          <div>
                            <span className="font-medium">Er heeft zich een probleem voorgedaan!</span> Probeer het later nog eens opnieuw.
                          </div>
                        </div>
                        :null}

                    {success ?
                        <div id="modelSuccessTriggerEnergies" className="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
                          <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Info</span>
                          <div>
                            <span className="font-medium">Het formulier is met success verzonden !</span> Iemand binnen ons team neemt weldra contact op met u.
                          </div>
                        </div>
                        : null}

                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="">

                        <div className="mt-3 text-center sm:mt-0  sm:text-left">
                          <Dialog.Title as="h1" className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	border-hasselt pl-2">
                            Energiescan aanvragen
                          </Dialog.Title>

                          <div className="mt-2">
                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Naam</label>
                              <input type="text" name="name" id="name" className={errName? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. Troetsaert"  />
                            </div>
                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Voornaam</label>
                              <input type="text" name="first_name" id="first_name" className={errName? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. Jens"  />
                            </div>
                            <div className="flex w-full space-x-4">
                              <div className="w-1/2">
                                <div className="">
                                  <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Straat</label>
                                  <input type="text" name="straat" id="straat" className={errAddress ? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5" : "bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. Herkenrodesingel"  />
                                </div>
                              </div>

                              <div className="w-1/2">
                                <div className="">
                                  <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Huisnr</label>
                                  <input type="text" name="huisnr" id="huisnr" className={errAddress ? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5" : "bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. 2"  />
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full space-x-4">
                              <div className="w-1/2"> 
                                <div className="">
                                  <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Postcode</label>
                                  <input type="text" name="postcode" id="postcode" className={errAddress ? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5" : "bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. 3500"  />
                                </div>
                              </div>

                              <div className="w-1/2"> 
                                <div className="">
                                  <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Gemeente</label>
                                  <input type="text" name="gemeente" id="gemeente" className={errAddress ? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5" : "bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. Hasselt"  />
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">E-mailadres</label>
                              <input type="text" name="email" id="email" className={errEmail? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. jeans@troetsaert.com" required />
                            </div>
                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Telefoonnummer</label>
                              <input type="text" name="phone" id="phone" className={errPhone? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} placeholder="vb. +32 478 03 87 67" required />
                            </div>

                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Hoedanigheid</label>
                              <select id="type" className={errType? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-12"} required>
                                <option selected value="1-Eigenaar">Eigenaar</option>
                                <option value="2-Huurder">Huurder</option>
                                <option value="3-Bewoner">Bewoner</option>
                                <option value="4-Partner">Partner</option>
                                <option value="5-Verhuurder">Verhuurder</option>
                              </select>
                            </div>
                            <div className="">
                              <label className="block md:text-base xl:text-base 2xl:text-base font-medium text-gray-900">Wanneer past dit het best voor je?</label>
                              <textarea id="comment" className={errComment? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900  rounded-lg block w-full p-2.5 h-24"} placeholder="vb. Maandag voormiddagen zijn het meest ideaal voor mij."></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      {!success ?
                          <>
                            <button
                                type="submit"
                                className="inline-flex m-1 justify-center rounded-md border border-transparent bg-hasselt text-white hover:bg-hasselt-dark focus:ring-4 focus:outline-none focus:ring-hasselt-accent font-medium rounded-lg text-sm px-5 py-2.5 text-center content-end "
                                // onClick={() => props.setOpen(false)}
                            >
                              Aanvragen
                            </button>
                            <button
                                type="button"
                                className="inline-flex m-1 justify-center rounded-md border border-black bg-white text-baclk hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-hasselt-accent font-medium rounded-lg text-sm px-5 py-2.5 text-center content-end "
                                onClick={() => props.setOpen(false)}
                                ref={cancelButtonRef}
                            >
                              Sluiten
                            </button>
                          </> :
                          <button
                              type="button"
                              className="inline-flex m-1 justify-center rounded-md border border-transparent bg-hasselt text-white hover:bg-hasselt-dark focus:ring-4 focus:outline-none focus:ring-hasselt-accent font-medium rounded-lg text-sm px-5 py-2.5 text-center content-end "
                              onClick={() => props.setOpen(false)}
                          >
                            Sluiten
                          </button>
                      }
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  );
};

export default EnergiescanAanvragen;
