import axios from "axios";

// GET API CAll
export const GetAPIService = (Url: any) => {
  return axios.get(process.env.REACT_APP_BACKEND_URL + Url);
};
// Post API CAll
export const PostAPIService = (Url: any, payload: any) => {
  return axios.post(process.env.REACT_APP_BACKEND_URL + Url, payload);
};
// Put API Call
export const PutAPIService = (Url: any, payload: any) => {
  return axios.put(process.env.REACT_APP_BACKEND_URL + Url, payload);
};
// Delete API CAll
export const DeleteAPIService = (Url: any, payload: any) => {
  return axios.delete(process.env.REACT_APP_BACKEND_URL + Url, payload);
};