import { openSession,getSession,updateSession } from "../context/actions/session";

import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

// method to create new Session or get existing one
export const Sessions = (cookies : any, setCookies : any ,dispatch: any) => {

  let userExist = parseInt(cookies?.userId);

  if (userExist !== null && userExist !== undefined && !Number.isNaN(userExist)) {

    const sessionData = cookies?.sessionData;
    const start_session = new Date (sessionData?.start_session);
    const sessionCreatedSince = moment().diff(start_session, 'hours')

    //if sessions is older than a day, then create new one
    if(sessionCreatedSince > 24){
      let sessionData: any = {};
      openSession()
        .then((response) => {
          sessionData = response.data;
          dispatch({
            type: "SET_SESSION_DATA",
            payload: sessionData,
          });
          setCookies("userId",sessionData.id)
          setCookies("sessionData",sessionData)
        })
        .catch((error) => {
          console.log("Error opening session",error);
        });
    }else{
      getSession(userExist).then((response)=>{
        let sessionData: any = {};
        sessionData = response.data.data.session[0];
        dispatch({
          type: "SET_SESSION_DATA",
          payload: sessionData,
        });
        setCookies("userId",sessionData.id)
        setCookies("sessionData",sessionData)

      }).catch((error) => {
          console.log("Error getting session",error);
      });
    }
  } else {
    // if the the visitor doesn't exist, then we call backend API the create a visitor token.
    let sessionData: any = {};
    openSession()
      .then((response) => {
        sessionData = response.data;
        dispatch({
          type: "SET_SESSION_DATA",
          payload: sessionData,
        });
        dispatch({
          type: "",
          payload: sessionData,
        });
        setCookies("userId",sessionData.id)
        setCookies("sessionData",sessionData)
      })
      .catch((error) => {
        console.log("error opening session",error);
      });
  }
};

// method to update a Session and start form

export const SessionsUpdate = (user: number, payload: object, cookies : any, setCookies : any ,dispatch: any) => {
  let sessionData: any = {};
  updateSession(user,payload).then((response) => {
    let sessionData = response.data.session;
    dispatch({
      type: "SET_SESSION_DATA",
      payload: sessionData,
    });
    dispatch({
      type: "SET_REDIRECT_FORM",
      payload: true,
    });
    setCookies("userId",sessionData.id)
    setCookies("sessionData",sessionData)
  })
  .catch((error) => {
    console.log("Error updating session",error);
  });
};


